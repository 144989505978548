import base from './base'
import instance from './http'

const list = {
  // 客服列表
  manageIndex(params) {
    return instance.post(`${base}/manage/v1/index/index`, params);
  },
  // 搜索某天数据
  oneSearch(params) {
    return instance.post(`${base}/manage/v1/index/oneSearch`, params);
  },
  // 搜索某天注册用户
  twoSearch(params) {
    return instance.post(`${base}/manage/v1/index/twoSearch`, params);
  },
  // 客服列表
  serviceIndex(params) {
    return instance.post(`${base}/manage/v1/service/index`, params);
  },
  // 添加、更新客服
  serviceSave (params) {
    return instance.post(`${base}/manage/v1/service/dataSave`, params)
  },
  // 客服详情
  serviceDetail (params) {
    return instance.post(`${base}/manage/v1/service/detail`, params)
  },
  // 为客户分配客服
  serviceShare (params) {
    return instance.post(`${base}/manage/v1/service/share_user`, params)
  },
  // 为客户分配客服
  serviceShowList (params) {
    return instance.post(`${base}/manage/v1/service/show_list`, params)
  },
  // 渠道列表
  channelIndex(params) {
    return instance.post(`${base}/manage/v1/channel/index`, params);
  },
  // 添加、更新渠道
  channelSave (params) {
    return instance.post(`${base}/manage/v1/channel/dataSave`, params)
  },
  // 渠道详情
  channelDetail (params) {
    return instance.post(`${base}/manage/v1/channel/detail`, params)
  },
  // 客户列表
  userIndex(params) {
    return instance.post(`${base}/manage/v1/user/index`, params);
  },
  // 添加、更新客户
  userSave (params) {
    return instance.post(`${base}/manage/v1/user/dataSave`, params)
  },
  // 客户详情
  userDetail (params) {
    return instance.post(`${base}/manage/v1/user/detail`, params)
  },
  //启用、禁用
  userSetStatus (params) {
    return instance.post(`${base}/manage/v1/user/setStatus`, params)
  },
  //更新部分字段
  userUpdate (params) {
    return instance.post(`${base}/manage/v1/user/dataUpdate`, params)
  },
  // 支付列表
  paymentIndex(params) {
    return instance.post(`${base}/manage/v1/payment/index`, params);
  },
  // 添加、更新支付
  paymentSave (params) {
    return instance.post(`${base}/manage/v1/payment/dataSave`, params)
  },
  // 订单列表
  orderIndex(params) {
    return instance.post(`${base}/manage/v1/order/index`, params);
  },
  // 补单
  orderRepair (params) {
    return instance.post(`${base}/manage/v1/order/repair`, params)
  },
  // 一键生成订单
  createOrder (params) {
    return instance.post(`${base}/manage/v1/order/create_order`, params)
  },
  // 关闭订单
  closeOrder (params) {
    return instance.post(`${base}/manage/v1/order/close_order`, params)
  },
  // 关闭所有订单
  closeAllOrder (params) {
    return instance.post(`${base}/manage/v1/order/close_all`, params)
  },
  // 发起支付
  launchOrder (params) {
    return instance.post(`${base}/manage/v1/order/launch_order`, params)
  },
  // 修改订单
  updateOrder (params) {
    return instance.post(`${base}/manage/v1/order/update_order`, params)
  },
  // 渠道统计
  channelStatIndex (params) {
    return instance.post(`${base}/manage/v1/channel/stat_index`, params)
  },
  // 客服
  serviceStatIndex (params) {
    return instance.post(`${base}/manage/v1/service/stat_index`, params)
  },
  appsflyerIndex (params) {
    return instance.post(`${base}/manage/v1/appsflyer/index`, params)
  },
  //获取输入记忆
  rememberIndex (params) {
    return instance.post(`${base}/manage/v1/remember/index`, params)
  },
  //存储输入记忆
  rememberSave (params) {
    return instance.post(`${base}/manage/v1/remember/dataSave`, params)
  },
  //获取所有输入记忆
  rememberAllIndex (params) {
    return instance.post(`${base}/manage/v1/remember/all_index`, params)
  },

}
export default list
